/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  faCheck,
  faCheckDouble,
  faMinus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Spinner from 'components/rootComponents/Standards/Spinner/Spinner';
import ToolTip from 'components/rootComponents/Standards/ToolTip/Tooltip';
import { IList, IOrder } from './List.interface';
import useStyles from './List.styles';
import ListHeaders from './ListHeaders/ListHeaders';
import ListPagination from './ListPagination/ListPagination';
import { DocumentStatus } from './enums/DocumentStatus.enum';

/**
 * Component to display elements as a table list.
 *
 * @component
 */
const List: FC<IList> = ({
  headers,
  rows,
  isLoading,
  page,
  setPage,
  rowsPerPage: initialRowsPerPage,
  setRowsPerPage,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  totalPages,
}) => {
  const [rowsPerPage, setInitialRowsPerPage] = useState(initialRowsPerPage);
  useEffect(() => {
    setInitialRowsPerPage(Math.min(rows.length, initialRowsPerPage));
  }, [rows, initialRowsPerPage]);

  const classes = useStyles();
  const history = useHistory();
  const handleRequestSort = (event: any, property: any) => {
    const isAsc = sortColumn === property && sortDirection === IOrder.ASC;
    setSortDirection(isAsc ? IOrder.DESC : IOrder.ASC);
    setSortColumn(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, rowsPerPage - rows.length);

  const evaluateCellType = (row: any, key: string, index: number) => {
    // prevent rendering links
    if (key === 'link') {
      return null;
    }

    const cellKey = `${row[key]}-${index}}`;
    const cellAlign =
      (!isNaN(row[key]) || key === 'calificacion') && key !== 'fecha_nacimiento'
        ? 'right'
        : 'left';

    if (key === 'probabilityOfQuitting' || key === 'probabilidad_abandono') {
      // row for the prob of quitting for students, adding color to the numbers
      let color = 'black';
      const valueNumber = row[key];
      if (valueNumber > 10 && valueNumber <= 50) {
        color = '#F2C12A';
      } else if (valueNumber > 50 && valueNumber <= 75) {
        color = '#EE7E27';
      } else if (valueNumber > 75) {
        color = '#F01B27';
      }

      return (
        <TableCell
          key={cellKey}
          className={classes.rowText}
          align={cellAlign}
          style={{ color }}
        >
          {`${row[key]} %`}
        </TableCell>
      );
    } else if (
      key === 'cedula' ||
      key === 'pase' ||
      key === 'carne_de_salud' ||
      key === 'carta_motivacional' ||
      key === 'constancia_domicilio' ||
      key === 'constancia_voto' ||
      key === 'curriculum_vitae' ||
      key === 'titulo_grado_escolaridad'
    ) {
      if (row[key] === DocumentStatus.VALIDADO) {
        return (
          <TableCell
            key={cellKey}
            className={classes.rowText}
            align={cellAlign}
          >
            <FontAwesomeIcon icon={faCheckDouble} style={{ color: 'green' }} />
          </TableCell>
        );
      } else if (row[key] === DocumentStatus.SUBIDO) {
        return (
          <TableCell
            key={cellKey}
            className={classes.rowText}
            align={cellAlign}
          >
            <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
          </TableCell>
        );
      } else if (row[key] === DocumentStatus.NO_CORRESPONDE) {
        return (
          <TableCell
            key={cellKey}
            className={classes.rowText}
            align={cellAlign}
          >
            <FontAwesomeIcon
              fontSize="inherit"
              icon={faMinus}
              style={{ color: 'blue' }}
            />
          </TableCell>
        );
      } else if (
        row[key] !== null &&
        row[key] !== DocumentStatus.NO_SUBIDO &&
        row[key] !== DocumentStatus.SUBIDO &&
        row[key] !== DocumentStatus.NO_CORRESPONDE
      ) {
        return (
          <TableCell
            key={cellKey}
            className={classes.rowText}
            align={cellAlign}
          >
            <ToolTip data={row[key]}>
              <FontAwesomeIcon
                fontSize="inherit"
                icon={faTimes}
                style={{ color: 'blue' }}
              />
            </ToolTip>
          </TableCell>
        );
      } else {
        return (
          <TableCell
            key={cellKey}
            className={classes.rowText}
            align={cellAlign}
          >
            <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
          </TableCell>
        );
      }
    } else if (key === 'plan_id') {
      return (
        <TableCell
          key={cellKey}
          className={classes.rowTextList}
          align={cellAlign}
        >
          {Array.isArray(row[key]) ? row[key].join('\n') : row[key]}
        </TableCell>
      );
    } else {
      // default behavior
      return (
        <TableCell key={cellKey} className={classes.rowText} align={cellAlign}>
          {row[key]}
        </TableCell>
      );
    }
  };
  const hasTituloGradoEscolaridad = headers.some(
    (header) => header.id === 'titulo_grado_escolaridad',
  );
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ maxHeight: 700 }}>
          <Table stickyHeader aria-labelledby="tableTitle" size="medium">
            {headers?.length > 0 ? (
              <>
                <ListHeaders
                  order={sortDirection}
                  orderBy={sortColumn}
                  onRequestSort={handleRequestSort}
                  classes={classes}
                  headers={headers}
                />
                <TableBody>
                  {isLoading ? (
                    // {isLoading && rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={headers.length}>
                        <Spinner />
                      </TableCell>
                    </TableRow>
                  ) : rows.length > 0 ? (
                    <>
                      {rows.map((row: any, index: number) => {
                        const keys = Object.keys(row);
                        const isConstanciaTIN3Required =
                          row.plan_id ===
                          'Tecnologías de la Información (TIN3)';
                        const isConstanciaADS1Required =
                          row.plan_id === 'Informática (ADS1)';

                        const style =
                          index % 2 === 0 ? classes.evenRow : classes.oddRow;
                        const requiredColumns =
                          isConstanciaTIN3Required || isConstanciaADS1Required
                            ? [
                                'cedula',
                                'pase',
                                'carne_de_salud',
                                'carta_motivacional',
                                'constancia_domicilio',
                              ]
                            : [
                                'cedula',
                                'pase',
                                'carne_de_salud',
                                'carta_motivacional',
                              ];
                        const isAnyColumnNotValidated = requiredColumns.some(
                          (column) => row[column] !== DocumentStatus.NO_SUBIDO,
                        );

                        const everyColumnValidated = requiredColumns.every(
                          (column) => row[column] === DocumentStatus.VALIDADO,
                        );
                        const gradeColumnsCorrespond = [
                          'curriculum_vitae',
                          'constancia_voto',
                          'titulo_grado_escolaridad',
                        ].every(
                          (column) =>
                            row[column] === DocumentStatus.SUBIDO ||
                            row[column] === DocumentStatus.NO_CORRESPONDE,
                        );
                        const isAllColumnsValidated =
                          everyColumnValidated && gradeColumnsCorrespond;

                        const backgroundColor = isAllColumnsValidated
                          ? 'rgba(0, 255, 0, 0.1)'
                          : isAnyColumnNotValidated
                          ? 'rgba(255, 0, 0, 0.1)'
                          : '';

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                            className={style}
                            style={{
                              backgroundColor: hasTituloGradoEscolaridad
                                ? backgroundColor
                                : 'none',
                            }}
                          >
                            {keys.map((key, cellIndex) => {
                              if (key === 'name' && row['link']) {
                                return (
                                  <TableCell key={cellIndex}>
                                    <Link
                                      className={classes.linkText}
                                      underline="hover"
                                      color="inherit"
                                      onClick={() => history.push(row['link'])}
                                    >
                                      {row[key]}
                                    </Link>
                                  </TableCell>
                                );
                              } else {
                                return evaluateCellType(row, key, cellIndex);
                              }
                            })}
                          </TableRow>
                        );
                      })}
                      {/* show empty rows to not break the design */}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={headers.length} />
                        </TableRow>
                      )}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={headers.length}>
                        No hay datos
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            ) : null}
          </Table>
        </TableContainer>

        <ListPagination
          rowsPerPageOptions={[20, 30, 50, 100]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          pagesTotal={totalPages}
        />
      </Paper>
    </Box>
  );
};

export default List;

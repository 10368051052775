import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useLists from 'utils/customHooks/useLists';

interface EncuestasCListadoUCProps {
  filters: any;
}

interface IListResponse {
  [key: string]: any;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  hiddenHeader: {
    display: 'none',
  },
  highlightedRow: {
    backgroundColor: '#00AEEF',
  },
  rowEven: {
    backgroundColor: '#f5f5f5',
  },
  rowOdd: {
    backgroundColor: '#ffffff',
  },
  tableCell: {
    whiteSpace: 'pre-line',
  },
  headerCell: {
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
  },
  commentsHeaderCell: {
    fontWeight: 'bold',
    color: '#000000',
  },
  commentsHeaderRow: {
    backgroundColor: '#00AEEF',
  },
  hiddenFirstRow: {
    '&:first-of-type': {
      display: 'none',
    },
  },
  notes: {
    marginTop: '20px',
    fontStyle: 'italic',
  }
});

const EncuestasCListadoUC: React.FC<EncuestasCListadoUCProps> = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  // Llamar a los hooks `useLists` fuera de `fetchData`
  const { users: answersResultsUC1S } = useLists('/v1.1/encuestascoordinador/listadoUC1S', props.filters) as { users: IListResponse[] };
  const { users: answersResultsUC2S } = useLists('/v1.1/encuestascoordinador/listadoUC2S', props.filters) as { users: IListResponse[] };
  const { users: comentariosUC1S } = useLists('/v1.1/encuestascoordinador/comentariosUC1S', props.filters) as { users: IListResponse[] };
  const { users: comentariosUC2S } = useLists('/v1.1/encuestascoordinador/comentariosUC2S', props.filters) as { users: IListResponse[] };

  const [results, setResults] = useState<{
    answersResultsUC1S: IListResponse[],
    answersResultsUC2S: IListResponse[],
    comentariosUC1S: IListResponse[],
    comentariosUC2S: IListResponse[]
  }>({
    answersResultsUC1S: [],
    answersResultsUC2S: [],
    comentariosUC1S: [],
    comentariosUC2S: []
  });

  // Nuevo `useEffect` para actualizar `results` y manejar la carga
  useEffect(() => {
    setLoading(true);
    setResults({
      answersResultsUC1S,
      answersResultsUC2S,
      comentariosUC1S,
      comentariosUC2S
    });
    setLoading(false);
  }, [answersResultsUC1S, answersResultsUC2S, comentariosUC1S, comentariosUC2S]);

  const cleanDuplicateNames = (name: string): string => {
    return name.replace(/(\b\w+\b)( \1)+/gi, '$1');
  };

  const transformComments = (data: IListResponse[]): any[][] => {
    if (data.length === 0) return [];

    const groupedComments: { [key: string]: string[] } = {};

    data.forEach(item => {
      const nombreDocente = item[0] || '';
      const comentario = item[1] || '';
      const cleanedNombreDocente = cleanDuplicateNames(nombreDocente);

      if (!groupedComments[cleanedNombreDocente]) {
        groupedComments[cleanedNombreDocente] = [];
      }

      groupedComments[cleanedNombreDocente].push(`• ${comentario}`);
    });

    const rows = Object.keys(groupedComments).map(nombreDocente => {
      const formattedComments = groupedComments[nombreDocente]
        .map(comment => comment.replace(/\n/g, '\n• '))
        .join('\n');
      return [nombreDocente, formattedComments];
    });

    return rows;
  };

  const transformData = (data: IListResponse[], headers: string[]): any[][] => {
    if (data.length === 0) return [];

    const rows = data.map(item => {
      const nombreDocente = item[0] || '';
      const curso = item[1] || '';
      const promedios = item.slice(2);
      const paddedPromedios = promedios.length < headers.length - 2 
        ? promedios.concat(Array(headers.length - 2 - promedios.length).fill('')) 
        : promedios.slice(0, headers.length - 2);

      return [nombreDocente, curso, ...paddedPromedios];
    });

    return rows;
  };

  const headersUC1S = ['Nombre Docente', 'Curso', 'Promedio 1', 'Promedio 2', 'Promedio 3', 'Promedio 4', 'Promedio 5'];
  const headersUC2S = ['Nombre Docente', 'Curso', 'Promedio 1', 'Promedio 2', 'Promedio 3', 'Promedio 4', 'Promedio 5'];

  const transformedAnswersResultsUC1S = transformData(results.answersResultsUC1S, headersUC1S);
  const transformedAnswersResultsUC2S = transformData(results.answersResultsUC2S, headersUC2S);
  const transformedComentariosUC1S = transformComments(results.comentariosUC1S);
  const transformedComentariosUC2S = transformComments(results.comentariosUC2S);

  const generateCSV = (headers: string[], rows: any[][]) => {
    const csvRows = [];
    csvRows.push(headers.join(','));
    rows.forEach(row => {
      csvRows.push(row.join(','));
    });
    return csvRows.join('\n');
  };

  const downloadCSV = (filename: string, csvContent: string) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = (filename: string, headers: string[], data: any[][]) => {
    const csvContent = generateCSV(headers, data);
    downloadCSV(filename, csvContent);
  };

  const renderTable = (data: any[][], hideFirstRow: boolean = false) => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            {data.map((row: any[], rowIndex: number) => (
              <TableRow
                key={rowIndex}
                className={
                  (rowIndex % 2 === 0 ? classes.rowEven : classes.rowOdd) +
                  (hideFirstRow && rowIndex === 0 ? ` ${classes.hiddenFirstRow}` : '')
                }
              >
                {row.map((cell: any, cellIndex: number) => (
                  <TableCell key={cellIndex} className={classes.tableCell}>
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Grid container spacing={2} style={{ marginTop: '0px' }}>
      <Grid item xs={12}>
        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <CircularProgress />
            <Typography variant="h6" style={{ marginTop: '10px' }}>Cargando...</Typography>
          </div>
        ) : (
          <>
            <Typography variant="h6" style={{ marginTop: '20px' }}>Reportes Coordinación UC</Typography>
            <Typography variant="h6" style={{ marginTop: '20px' }}>Promedios Unidades Curriculares 1S</Typography>
            {transformedAnswersResultsUC1S.length > 0 && (
              <>
                {renderTable(transformedAnswersResultsUC1S, true)}
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleExport('promedios_UC1S.csv', headersUC1S, transformedAnswersResultsUC1S)}
                  style={{ margin: '10px' }}
                >
                  Exportar UC1S
                </Button>
              </>
            )}
            <Typography variant="h6" style={{ marginTop: '20px' }}>Comentarios Unidades Curriculares 1S</Typography>
            {transformedComentariosUC1S.length > 0 && (
              <>
                {renderTable(transformedComentariosUC1S)}
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleExport('comentarios_UC1S.csv', ['Nombre Docente', 'Comentarios'], transformedComentariosUC1S)}
                  style={{ margin: '10px' }}
                >
                  Exportar Comentarios UC1S
                </Button>
              </>
            )}
            <Typography variant="h6" style={{ marginTop: '20px' }}>Promedios Unidades Curriculares 2S</Typography>
            {transformedAnswersResultsUC2S.length > 0 && (
              <>
                {renderTable(transformedAnswersResultsUC2S, true)}
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleExport('promedios_UC2S.csv', headersUC2S, transformedAnswersResultsUC2S)}
                  style={{ margin: '10px' }}
                >
                  Exportar UC2S
                </Button>
              </>
            )}
            <Typography variant="h6" style={{ marginTop: '20px' }}>Comentarios Unidades Curriculares 2S</Typography>
            {transformedComentariosUC2S.length > 0 && (
              <>
                {renderTable(transformedComentariosUC2S)}
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleExport('comentarios_UC2S.csv', ['Nombre Docente', 'Comentarios'], transformedComentariosUC2S)}
                  style={{ margin: '10px' }}
                >
                  Exportar Comentarios UC2S
                </Button>
              </>
            )}
            <Typography className={classes.notes}>
              (*) El promedio refiere al grado de acuerdo con que se desarrollan los items consultados, donde 1 es Totalmente en Desacuerdo y 4 es Totalmente de Acuerdo.
              <br />
              (**) No se incluye en el promedio las respuestas 'No sabe/No contesta'. Las UCs con menos de cuatro respuestas no se evalúan para mantener el anonimato de los estudiantes.
            </Typography>
            <Typography className={classes.notes}>
              Los comentarios corresponden a los estudiantes que consideran que el grado de dificultad de la UC es 'Muy difícil' o 'Difícil'.
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  filters: state.filters,
});

export default connect(mapStateToProps)(EncuestasCListadoUC);
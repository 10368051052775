import React from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Box,
  Menu,
  AppBar,
  Toolbar,
  MenuItem,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';

import FirstStepsModal from 'components/rootComponents/Modal/FirstSteps/FirstStepsModal';
import FAQModal from 'components/rootComponents/Modal/FAQ/FAQModal';
import UserService from 'services/user/user.service';
import { removeUserFromSession } from 'store/actions/userInfo/userAction';
import { INavbar, INavLinks } from './Navbar.interface';
import useStyles from './Navbar.styles';
let username = ' ';
/**
 * Generic component to render navbar using [[INavbar]] as props
 *
 * @component
 */
const Navbar = (props: INavbar) => {
  const classes = useStyles();
  const history = useHistory();
  const { navLinksList } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [helpEl, setHelpEl] = React.useState(null);
  const [surveyEl, setSurveyEl] = React.useState(null); // New state for the survey submenu
  const [surveyElR, setSurveyElR] = React.useState(null); // New state for the survey submenu
  const loggedUser = UserService.getSessionUser();

  if (loggedUser && loggedUser.email) {
    const email = loggedUser.email;
    username = email.split('@')[0]; 
  } 

  const handleMenu = (event: { currentTarget: any }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    handleClose();
    // log out user
    UserService.sendLogout();
    props.removeUserFromSession();
    history.push('/login');
  };

  const handleHelp = (event: { currentTarget: any }) => {
    setHelpEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setHelpEl(null);
  };

  const handleSurvey = (event: { currentTarget: any }) => {
    setSurveyEl(event.currentTarget);
  };

  const handleSurveyClose = () => {
    setSurveyEl(null);
  };

  const handleSurveyR = (event: { currentTarget: any }) => {
    setSurveyElR(event.currentTarget);
  };

  const handleSurveyCloseR = () => {
    setSurveyElR(null);
  };

  

  /* mobile navbar */
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }} mb={2}>
      <AppBar
        position="fixed"
        color="primary"
        style={{
          backgroundColor: '#009bdb',
        }}
      >
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              textTransform: 'uppercase',
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navLinksList.map((item: INavLinks, index: number) => {
                const { nombre, link, enabled } = item;

                if (nombre === 'Ayuda') {
                  return (
                    <MenuItem key={index}>
                      <p
                        style={{
                          color: enabled ? 'black' : 'grey',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          margin: '0',
                        }}
                        onClick={handleHelp}
                      >
                        {nombre}
                        <ExpandMore style={{ float: 'right' }} />
                      </p>
                      <Menu
                        id="menu-help"
                        anchorEl={helpEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(helpEl)}
                        onClose={handleHelpClose}
                      >
                        <MenuItem onClick={handleHelpClose}>
                          <FirstStepsModal />
                        </MenuItem>
                        <MenuItem disabled={true}>Tutoriales</MenuItem>
                        <MenuItem onClick={handleHelpClose}>
                          <FAQModal />
                        </MenuItem>
                      </Menu>
                    </MenuItem>
                  );
                } else if (nombre === 'Encuestas') { // New button for Encuestas submenu
                  return (
                    <MenuItem key={index}>
                      <p
                        style={{
                          color: enabled ? 'black' : 'grey',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          margin: '0',
                        }}
                        onClick={handleSurvey}
                      >
                        {nombre}
                        <ExpandMore style={{ float: 'right' }} />
                      </p>
                      <Menu
                        id="menu-encuestas"
                        anchorEl={surveyEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(surveyEl)}
                        onClose={handleSurveyClose}
                      >
                        <MenuItem onClick={handleSurveyClose}>
                          <Link
                            to={'/v1.1/navegacion/encuestas/graficas'}
                            style={{ textDecoration: 'none',  color: '#1976d2', padding: '10px 20px', fontSize: '0.9em', fontWeight: 'bold'}}
                          >
                            ENCUESTAS DOCENTES
                          </Link>
                        </MenuItem> 
                        <MenuItem onClick={handleSurveyClose}>
                          <Link
                            to={'/v1.1/navegacion/encuestasuc/graficasuc'}
                            style={{ textDecoration: 'none',  color: '#1976d2', padding: '10px 20px', fontSize: '0.9em', fontWeight: 'bold' }}
                          >
                            ENCUESTAS UC
                          </Link>
                        </MenuItem>
                      </Menu>
                    </MenuItem>
                  );
                } else if (nombre === 'Reporte') { // New button for Encuestas submenu
                  return (
                    <MenuItem key={index}>
                      <p
                        style={{
                          color: enabled ? 'black' : 'grey',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          margin: '0',
                        }}
                        onClick={handleSurveyR}
                      >
                        {nombre}
                        <ExpandMore style={{ float: 'right' }} />
                      </p>
                      <Menu
                        id="menu-reportes"
                        anchorEl={surveyElR}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(surveyElR)}
                        onClose={handleSurveyCloseR}
                      >
                        <MenuItem onClick={handleSurveyCloseR}>
                          <Link
                            to={`/v1.1/navegacion/coordinador/listas/${username}`}
                            style={{ textDecoration: 'none',  color: '#1976d2', padding: '10px 20px', fontSize: '0.9em', fontWeight: 'bold'}}
                          >
                            REPORTES DOCENTES
                          </Link>
                        </MenuItem> 
                        <MenuItem onClick={handleSurveyCloseR}>
                          <Link
                            to={`/v1.1/navegacion/coordinadoruc/listas/${username}`}
                            style={{ textDecoration: 'none',  color: '#1976d2', padding: '10px 20px', fontSize: '0.9em', fontWeight: 'bold' }}
                          >
                            REPORTES UC
                          </Link>
                        </MenuItem>
                      </Menu>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={index} onClick={handleCloseNavMenu}>
                      <NavLink
                        className={nombre === 'AA' ? classes.homeMobile : ''}
                        style={{
                          color: enabled ? 'black' : 'grey',
                          textDecoration: 'none',
                          height: '100%',
                        }}
                        to={enabled ? link : ''}
                      >
                        {nombre}
                      </NavLink>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              textTransform: 'uppercase',
            }}
          >
            {navLinksList.map((item: INavLinks, index: number) => {
              const { nombre, link, enabled } = item;

              if (nombre === 'Ayuda') {
                return (
                  <Button
                    key={index}
                    sx={{
                      display: 'block',
                      color: enabled ? 'white' : 'grey',
                      textDecoration: 'none',
                      cursor: 'default',
                    }}
                  >
                    <p
                      style={{
                        color: enabled ? 'white' : 'grey',
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={handleHelp}
                    >
                      {nombre}
                      <ExpandMore style={{ float: 'right' }} />
                    </p>
                    <Menu
                      id="menu-help"
                      anchorEl={helpEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(helpEl)}
                      onClose={handleHelpClose}
                    >
                      <MenuItem onClick={handleHelpClose}>
                        <FirstStepsModal />
                      </MenuItem>
                      <MenuItem disabled={true}>Tutoriales</MenuItem>
                      <MenuItem onClick={handleHelpClose}>
                        <FAQModal />
                      </MenuItem>
                    </Menu>
                  </Button>
                );
              } else if (nombre === 'Encuestas') { // New button for Encuestas submenu
                return (
                  <Button
                    key={index}
                    sx={{
                      display: 'block',
                      color: enabled ? 'white' : 'grey',
                      textDecoration: 'none',
                      cursor: 'default',
                    }}
                  >
                    <p
                      style={{
                        color: enabled ? 'white' : 'grey',
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={handleSurvey}
                    >
                      {nombre}
                      <ExpandMore style={{ float: 'right' }} />
                    </p>
                    <Menu
                      id="menu-encuestas"
                      anchorEl={surveyEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(surveyEl)}
                      onClose={handleSurveyClose}
                    >
                      <MenuItem onClick={handleSurveyClose}>
                        <Link
                          to={'/v1.1/navegacion/encuestas/graficas'}
                          style={{ textDecoration: 'none',  color: '#1976d2', padding: '10px 20px', fontSize: '0.9em', fontWeight: 'bold'}}
                        >
                          ENCUESTAS DOCENTES
                        </Link>
                      </MenuItem> 
                      <MenuItem onClick={handleSurveyClose}>
                        <Link
                          to={'/v1.1/navegacion/encuestasuc/graficasuc'}
                          style={{ textDecoration: 'none',  color: '#1976d2', padding: '10px 20px', fontSize: '0.9em', fontWeight: 'bold' }}
                        >
                          ENCUESTAS UC
                        </Link>
                      </MenuItem>
                    </Menu>
                  </Button>
                );
              } else if (nombre === 'Reporte') { // New button for Encuestas submenu
                return (
                  <Button
                    key={index}
                    sx={{
                      display: 'block',
                      color: enabled ? 'white' : 'grey',
                      textDecoration: 'none',
                      cursor: 'default',
                    }}
                  >
                    <p
                      style={{
                        color: enabled ? 'white' : 'grey',
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={handleSurveyR}
                    >
                      {nombre}
                      <ExpandMore style={{ float: 'right' }} />
                    </p>
                    <Menu
                      id="menu-reportes"
                      anchorEl={surveyElR}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(surveyElR)}
                      onClose={handleSurveyCloseR}
                    >
                      <MenuItem onClick={handleSurveyCloseR}>
                        <Link
                          to={'/v1.1/navegacion/coordinador/listas/:coordU'}
                          style={{ textDecoration: 'none',  color: '#1976d2', padding: '10px 20px', fontSize: '0.9em', fontWeight: 'bold'}}
                        >
                          REPORTES DOCENTES
                        </Link>
                      </MenuItem> 
                      <MenuItem onClick={handleSurveyCloseR}>
                        <Link
                          to={`/v1.1/navegacion/coordinador/listas/uc/${username}`}
                          style={{ textDecoration: 'none',  color: '#1976d2', padding: '10px 20px', fontSize: '0.9em', fontWeight: 'bold' }}
                        >
                          REPORTES UC
                        </Link>
                      </MenuItem>
                    </Menu>
                  </Button>
                );
              } else {
                return (
                  <Button
                    key={index}
                    sx={{ color: 'white', display: 'block', cursor: 'default' }}
                  >
                    <NavLink
                      className={nombre === 'AA' ? classes.home : ''}
                      style={{
                        color: enabled ? 'white' : 'grey',
                        textDecoration: 'none',
                        height: '100%',
                      }}
                      to={enabled ? link : ''}
                    >
                      {nombre}
                    </NavLink>
                  </Button>
                );
              }
            })}
          </Box>
          {props.isLoggedIn && (
            <>
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleMenu}
                >
                  <AccountCircle />
                </IconButton>
              </div>
              <div>
                <Typography
                  sx={{
                    flexGrow: 1,
                    cursor: 'pointer',
                  }}
                  onClick={handleMenu}
                >
                  {loggedUser?.username}
                </Typography>
                <Typography
                  sx={{
                    flexGrow: 1,
                    cursor: 'pointer',
                  }}
                  onClick={handleMenu}
                >
                  {loggedUser?.rol}
                </Typography>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <Link
                      to={'/profile'}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      Mi perfil
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>Cerrar Sesión</MenuItem>
                </Menu>
              </div>
            </>
          )}
          {!props.isLoggedIn && (
            <NavLink
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
              to={'/login'}
            >
              Ingresar
            </NavLink>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { removeUserFromSession })(Navbar);

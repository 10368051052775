import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { IRoutes } from 'components/rootComponents/Breadcrumbs/CustomBreadcrumbs.interface';
import CustomBreadcrumbs from 'components/rootComponents/Breadcrumbs/CustomBreadcrumbs';

const routes: IRoutes[] = [
  {
    path: '/v1.1/navegacion/postulacionesm/listado',
    alias: 'UC Aprobadas',
  },
  {
    path: '/v1.1/navegacion/coordinador/listas/',
    alias: 'Reportes',
  },
  {
    path: '/v1.1/navegacion/coordinadoruc/listas/',
    alias: 'Reportes UC',
  },
  {
    path: '/v1.1/navegacion/encuestas/graficas',
    alias: 'Encuestas Docentes',
  },
  {
    path: '/v1.1/navegacion/encuestasuc/graficasuc',
    alias: 'Encuestas UC',
  },

  {
    path: '/v1.1/navegacion/inicio/graficas',
    alias: 'Inicio',
    hide: true,
  },
  {
    path: '/v1.1/navegacion/actividad_digital',
    alias: 'Actividad Digital',
    children: [
      {
        path: '/v1.1/navegacion/actividad_digital/graficas',
        alias: 'Gráficas',
        default: true,
      },
      {
        path: '/v1.1/navegacion/actividad_digital/moodle',
        alias: 'Moodle',
      },
      {
        path: '/v1.1/navegacion/actividad_digital/edu',
        alias: 'Edu',
      },
    ],
  },
  {
    path: '/v1.1/navegacion/estudiantes',
    alias: 'Estudiantes',
    children: [
      {
        path: '/v1.1/navegacion/estudiantes/graficas',
        alias: 'Gráficas',
        default: true,
      },
      {
        path: '/v1.1/navegacion/estudiantes/listado',
        alias: 'Listado',
      },
      {
        path: '/v1.1/navegacion/estudiantes/perfil',
        alias: 'Perfil',
        default: true,
        children: [
          {
            path: '/v1.1/navegacion/estudiantes/perfil/laboral',
            alias: 'Laboral',
          },
          {
            path: '/v1.1/navegacion/estudiantes/perfil/educativo',
            alias: 'Educativo',
          },
          {
            path: '/v1.1/navegacion/estudiantes/perfil/socio',
            alias: 'Socio',
          },
        ],
      },
      {
        path: '/v1.1/navegacion/estudiantes/calificaciones',
        alias: 'Calificaciones',
      },
      {
        path: '/v1.1/navegacion/estudiantes/estudiante',
        alias: 'Estudiante',
      },
      {
        path: '/v1.1/navegacion/estudiantes/escolaridad',
        alias: 'Escolaridad',
      },
    ],
  },
  {
    path: '/v1.1/navegacion/grupos',
    alias: 'Grupos',
    children: [
      {
        path: '/v1.1/navegacion/grupos/graficas',
        alias: 'Gráficas',
        default: true,
      },
      {
        path: '/v1.1/navegacion/grupos/listado',
        alias: 'Listado',
      },
      {
        path: '/v1.1/navegacion/grupos/grupo',
        alias: 'Grupo',
        default: true,
        children: [
          {
            path: '/v1.1/navegacion/grupos/grupo/calificaciones',
            alias: 'Calificaciones',
          },
        ],
      },
      {
        path: '/v1.1/navegacion/grupos/estudiantes',
        alias: 'Estudiantes',
      },
    ],
  },
  {
    path: '/v1.1/navegacion/postulaciones',
    alias: 'Postulaciones',
    children: [
      {
        path: '/v1.1/navegacion/postulaciones/graficas',
        alias: 'Gráficas',
        default: true,
      },
      {
        path: '/v1.1/navegacion/postulaciones/listado',
        alias: 'Listado',
      },
      {
        path: '/v1.1/navegacion/postulaciones/postulacion',
        alias: 'Postulante',
      },
    ],
  },
  {
    path: '/v1.1/navegacion/asignaturas',
    alias: 'Asignaturas',
    children: [
      {
        path: '/v1.1/navegacion/asignaturas/graficas',
        alias: 'Gráficas',
      },
      {
        path: '/v1.1/navegacion/asignaturas/listado',
        alias: 'Listado',
        default: true,
      },
    ],
  },
  {
    path: '/v1.1/navegacion/academica',
    alias: 'Académica',
    children: [
      {
        path: '/v1.1/navegacion/academica/graficas',
        alias: 'Gráficas',
      },
      {
        path: '/v1.1/navegacion/academica/listado',
        alias: 'Listado',
        default: true,
      },
    ],
  },
  {
    path: '/v1.1/navegacion/recursantes',
    alias: 'Recursantes',
    children: [
      {
        path: '/v1.1/navegacion/recursantes/graficas',
        alias: 'Gráficas',
      },
      {
        path: '/v1.1/navegacion/recursantes/listado',
        alias: 'Listado',
        default: true,
      },
    ],
  },
  {
    path: '/v1.1/navegacion/docentes',
    alias: 'Docentes',
    children: [
      {
        path: '/v1.1/navegacion/docentes/graficas',
        alias: 'Gráficas',
        default: true,
      },
      {
        path: '/v1.1/navegacion/docentes/listado',
        alias: 'Listado',
      },
      {
        path: '/v1.1/navegacion/docentes/docente',
        alias: 'Docente',
      },
    ],
  },
  {
    path: '/v1.1/navegacion/uruguay_global',
    alias: 'Uruguay Global',
    children: [
      {
        path: '/v1.1/navegacion/uruguay_global/graficas',
        alias: 'Gráficas',
        default: true,
      },
      {
        path: '/v1.1/navegacion/uruguay_global/listado',
        alias: 'Listado',
      },
    ],
  },
  {
    path: '/v1.1/navegacion/egresados',
    alias: 'Egresados',
    children: [
      {
        path: '/v1.1/navegacion/egresados/graficas',
        alias: 'Gráficas',
        default: true,
      },
      {
        path: '/v1.1/navegacion/egresados/listado',
        alias: 'Listado',
      },
    ],
  },
  {
    path: '/contacto',
    alias: 'Contacto',
  },
];

const getPath = (
  currentPath: string,
  routes: IRoutes[],
  breadcrumbsArray: IRoutes[],
) => {
  const result = routes.find(({ path }: IRoutes) => {
    return currentPath.includes(path) || currentPath === path;
  });

  if (result) {
    breadcrumbsArray.push(result);

    if (result?.children) {
      breadcrumbsArray = getPath(
        currentPath,
        result.children,
        breadcrumbsArray,
      );
    }
  }

  return breadcrumbsArray;
};

const BreadcrumbsSection = () => {
  const history = useHistory();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState(
    getPath(location.pathname, routes, []),
  );

  useEffect(() => {
    return history.listen((location: any) => {
      setBreadcrumbs(getPath(location.pathname, routes, []));
    });
  }, [history]);

  return <CustomBreadcrumbs crumbs={breadcrumbs} />;
};

export default BreadcrumbsSection;

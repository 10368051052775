import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { Container, Grid } from '@mui/material';

import Navbar from 'components/rootComponents/Navbar/Navbar';
import UserService, { getToken } from 'services/user/user.service';
import {
  readTokenFromSession,
  saveUserLogIn,
} from 'store/actions/userInfo/userAction';
import ActividadAcademicaGraficas from 'views/ActividadAcademica/graficas/ActividadAcademicaGraficas';
import ActividadAcademicaListado from 'views/ActividadAcademica/listado/ActividadAcademicaListado';
import ActividadDigital from 'views/ActividadDigital/ActividadDigital';
import Edu from 'views/ActividadDigital/Edu/Edu';
import Moodle from 'views/ActividadDigital/Moodle/Moodle';
import AsignaturasGraficas from 'views/Asignaturas/graficas/AsignaturasGraficas';
import AsignaturasListado from 'views/Asignaturas/listado/AsignaturasListado';
import Contacto from 'views/Contacto/Contacto';
import DocentesGraficas from 'views/Docentes/graficas/DocentesGraficas';
import DocentesListado from 'views/Docentes/listado/DocentesListado';
import Calificaciones from 'views/Estudiantes/calificaciones/Calificaciones';
import EstudiantesGraficas from 'views/Estudiantes/graficas/EstudiantesGraficas';
import EstudiantesListado from 'views/Estudiantes/listado/EstudiantesListado';
import GruposGraficas from 'views/Grupos/graficas/GruposGraficas';
import Grupo from 'views/Grupos/Grupo';
import GruposListadoEstudiantes from 'views/Grupos/GruposListadoEstudiantes';
import GruposListado from 'views/Grupos/listado/GruposListado';
import Home from 'views/Home/Home';
import LogoutHome from 'views/Home/LogoutHome/LogoutHome';
import LogIn from 'views/LogIn/LogIn';
import PostulacionesGraficas from 'views/Postulaciones/graficas/PostulacionesGraficas';
import PostulacionesListado from 'views/Postulaciones/listado/PostulacionesListado';
import PostulacionesListadoM from 'views/Postulaciones/listado/PostulacionesListadoM';
import EncuestasCListado from 'views/Postulaciones/listado/EncuestasCListado';
import EncuestasCListadoUC from 'views/Postulaciones/listado/EncuestasCListadoUC';
import Profile from 'views/Profile/Profile';
import RecursantesGraficas from 'views/Recursantes/graficas/RecursantesGraficas';
import RecursantesListado from 'views/Recursantes/listado/RecursantesListado';
import BreadcrumbsSection from 'views/Root/Breadcrumbs/Breadcrumbs';
import Filters from 'views/Root/Filters/Filters';
import Footer from 'views/Root/Footer/Footer';
import PageNavbar from 'views/Root/PageNavbar/PageNavbar';
import PerfilDocente from 'views/Shared/PerfilDocente/PerfilDocente';
import PerfilEstudiante from 'views/Shared/PerfilEstudiante/PerfilEstudiante';
import PerfilPostulante from 'views/Shared/PerfilPostulante/PerfilPostulante';
import './App.css';
import Escolaridad from 'views/Escolaridad/Escolaridad';
import EncuestasGraficas from 'views/Encuestas/graficas/EncuestasGraficas';
import EncuestasListado from 'views/Encuestas/listado/EncuestasListado';
import EncuestasGraficasUC from 'views/Encuestas/graficas/EncuestasGraficasUC';
import EncuestasListadoUC from 'views/Encuestas/listado/EncuestasListadoUC';

import EncuestasCoordinador from 'views/Encuestas/graficas/EncuestasCoordinador';
import EncuestasCoordinadorUC from 'views/Encuestas/graficas/EncuestasCoordinadorUC';
import EstudiantesMListado from 'views/Estudiantes/listado/EstudiantesMListado';

import UruguayGlobalGraficas from 'views/UruguayGlobal/graficas/UruguayGlobalGraficas';
import UruguayGlobalListado from 'views/UruguayGlobal/listado/UruguayGlobalListado';
import EgresadosGraficas from 'views/Egresados/graficas/EgresadosGraficas';
import EgresadosListado from 'views/Egresados/listado/EgresadosListado';

/**
 * Base component to render the App
 * It contains the navbar, router and other cross/shared components
 *
 * @component
 */
const App = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let isStudent = false;
  const notLoggedInLinks = [
    { nombre: 'AA', link: '/home', enabled: true },
    { nombre: 'Contacto', link: '/contacto', enabled: true },
  ];

  if (isLoggedIn) {
    const sessionUser = UserService.getSessionUser();
    if (sessionUser && sessionUser.rol) {
      isStudent = sessionUser.rol.toLowerCase().includes('estudiante');
    }
  }
  useEffect(() => {
    // if no value, or user is not logged in
    if (!props.userInfo || !props.userInfo.loggedIn) {
      // if no token on session AND not on login then redirect to login
      if (getToken() === '') {
        if (
          window.location.pathname !== '/home' &&
          window.location.pathname !== '/login' &&
          window.location.pathname !== '/api' &&
          window.location.pathname !== '/contacto'
        ) {
          window.location.href = '/login';
        }
      } else {
        // if we have a token then the user is logged in, update redux as most likely they F5
        props.readTokenFromSession();
      }
    }
    setIsLoggedIn(props.userInfo.loggedIn);
  }, [props.userInfo]);
  return (
    <>
      <Container
        sx={{
          backgroundColor: 'white',
          minHeight: '65vh',
        }}
        maxWidth="xl"
      >
        <Router>
          {isLoggedIn && (
            <>
              <PageNavbar isLoggedIn={isLoggedIn} />
              {!isStudent && <BreadcrumbsSection />}
            </>
          )}

          {!isLoggedIn && <Navbar navLinksList={notLoggedInLinks} />}
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={3}
              style={{
                display: `${
                  isLoggedIn && !isStudent && props.userInfo?.visibility
                    ? ''
                    : 'none'
                }`,
              }}
            >
              <Filters />
            </Grid>
            <Grid
              item
              xs={12}
              md={isLoggedIn && props.userInfo?.visibility ? 9 : 12}
            >
              <Switch>
                <Route path="/login" component={LogIn} />
                <Route path="/home" component={LogoutHome} />
                <Route
                  path="/v1.1/navegacion/inicio/graficas"
                  component={Home}
                />
                <Route
                  path="/v1.1/navegacion/estudiantes/graficas"
                  component={EstudiantesGraficas}
                />
                <Route
                  path="/v1.1/navegacion/estudiantes/listado"
                  component={EstudiantesListado}
                />
                <Route
                  path="/v1.1/navegacion/estudiantes/listado"
                  component={EstudiantesMListado}
                />
                <Route
                  path="/v1.1/navegacion/estudiantes/estudiante"
                  component={PerfilEstudiante}
                />
                <Route
                  path="/v1.1/navegacion/estudiantes/escolaridad"
                  component={Escolaridad}
                />
                <Route path="/api/v1.1/navegacion/estudiantes/calificaciones">
                  <Redirect to="/v1.1/navegacion/estudiantes/calificaciones" />
                </Route>
                <Route
                  path="/v1.1/navegacion/estudiantes/calificaciones"
                  component={Calificaciones}
                />
                <Route
                  path="/v1.1/navegacion/estudiantes/perfil"
                  component={Calificaciones}
                />
                <Route
                  path="/v1.1/navegacion/estudiantes/perfil/:perfil"
                  component={Calificaciones}
                />
                <Route
                  path="/v1.1/navegacion/actividad_digital/graficas"
                  component={ActividadDigital}
                />
                <Route path="/api/v1.1/navegacion/actividad_digital/moodle">
                  <Redirect to="/v1.1/navegacion/actividad_digital/moodle" />
                </Route>
                <Route
                  path="/v1.1/navegacion/actividad_digital/moodle"
                  component={Moodle}
                />
                <Route path="/api/v1.1/navegacion/actividad_digital/edu">
                  <Redirect to="/v1.1/navegacion/actividad_digital/edu" />
                </Route>
                <Route
                  path="/v1.1/navegacion/actividad_digital/edu"
                  component={Edu}
                />
                <Route
                  path="/v1.1/navegacion/grupos/graficas"
                  component={GruposGraficas}
                />
                <Route
                  path="/v1.1/navegacion/grupos/listado"
                  component={GruposListado}
                />
                <Route
                  path="/v1.1/navegacion/academica/graficas"
                  component={ActividadAcademicaGraficas}
                />
                <Route
                  path="/v1.1/navegacion/academica/listado"
                  component={ActividadAcademicaListado}
                />
                <Route
                  path="/v1.1/navegacion/asignaturas/graficas"
                  component={AsignaturasGraficas}
                />
                <Route
                  path="/v1.1/navegacion/asignaturas/listado"
                  component={AsignaturasListado}
                />
                <Route
                  path="/v1.1/navegacion/recursantes/graficas"
                  component={RecursantesGraficas}
                />
                <Route
                  path="/v1.1/navegacion/recursantes/listado"
                  component={RecursantesListado}
                />
                <Route path="/v1.1/navegacion/grupos/grupo" component={Grupo} />
                <Route
                  path="/v1.1/navegacion/grupos/grupo/calificaciones"
                  component={Grupo}
                />
                <Route
                  path="/v1.1/navegacion/grupos/estudiantes"
                  component={GruposListadoEstudiantes}
                />
                <Route
                  path="/v1.1/navegacion/postulaciones/graficas"
                  component={PostulacionesGraficas}
                />
                <Route
                  path="/v1.1/navegacion/postulaciones/listado"
                  component={PostulacionesListado}
                />
                <Route
                  path="/v1.1/navegacion/postulacionesm/listado"
                  component={PostulacionesListadoM}
                />
                <Route
                  path="/v1.1/navegacion/postulaciones/postulacion"
                  component={PerfilPostulante}
                />
                <Route
                  path="/v1.1/navegacion/docentes/graficas"
                  component={DocentesGraficas}
                />
                <Route
                  path="/v1.1/navegacion/docentes/listado"
                  component={DocentesListado}
                />
                <Route
                  path="/v1.1/navegacion/docentes/docente"
                  component={PerfilDocente}
                />
                <Route
                  path="/v1.1/navegacion/encuestas/graficas"
                  component={EncuestasGraficas}
                />
                <Route
                  path="/v1.1/navegacion/encuestas/detalles"
                  component={EncuestasGraficas}
                />
                <Route
                  path="/v1.1/navegacion/encuestas/listado"
                  component={EncuestasListado}
                />
                <Route
                  path="/v1.1/navegacion/encuestasuc/graficasuc"
                  component={EncuestasGraficasUC}
                />
                <Route
                  path="/v1.1/navegacion/encuestasuc/listadouc"
                  component={EncuestasListadoUC}
                />
                <Route
                  path="/v1.1/navegacion/encuestasuc/detallesuc"
                  component={EncuestasGraficasUC}
                />
                <Route
                  path="/v1.1/navegacion/encuestascoordinador/listado"
                  component={EncuestasCListado}
                />
                <Route
                  path="/v1.1/navegacion/encuestascoordinador/listadoUC"
                  component={EncuestasCListadoUC}
                />
                <Route
                  path="/v1.1/navegacion/encuestascoordinador/listadoUC"
                  component={EncuestasCListadoUC}
                />
                <Route
                  path="/v1.1/navegacion/coordinador/listas/:coordU"
                  component={EncuestasCoordinador}
                />
                <Route
                  path="/v1.1/navegacion/coordinadoruc/listas/:coordU"
                  component={EncuestasCoordinadorUC}
                />
                <Route
                  path="/v1.1/navegacion/uruguay_global/graficas"
                  component={UruguayGlobalGraficas}
                />
                <Route
                  path="/v1.1/navegacion/uruguay_global/listado"
                  component={UruguayGlobalListado}
                />
                <Route
                  path="/v1.1/navegacion/egresados/graficas"
                  component={EgresadosGraficas}
                />
                <Route
                  path="/v1.1/navegacion/egresados/listado"
                  component={EgresadosListado}
                />
                <Route path="/profile" component={Profile} />
                <Route path="/contacto" component={Contacto} />
                <Route path="/profile" component={Profile} />
              </Switch>
            </Grid>
          </Grid>
        </Router>
      </Container>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return { userInfo: state.userInfo };
};

export default connect(mapStateToProps, {
  saveUserLogIn,
  readTokenFromSession,
})(App);

import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { IYearPicker } from './YearPicker.interface';
import useStyles from './YearPicker.styles';

/**
 * Component used to render an Year picker for the filter view
 *
 * @component
 */
const YearPicker = ({ selectedYear, setSelectedYear, yearsArray, children }: IYearPicker) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      {children}

      <div className={classes.selectedYearContainer} onClick={() => setIsOpen(!isOpen)}>
        <CalendarTodayIcon className={`${classes.yearFilterIcon} ${classes.calendarIcon}`} />
        <span className={classes.selectedYear}>{selectedYear}</span>
        {isOpen ? (
          <KeyboardArrowDownIcon className={`${classes.yearFilterIcon} ${classes.arrowDownIcon}`} />
        ) : (
          <KeyboardArrowLeftIcon className={`${classes.yearFilterIcon} ${classes.arrowDownIcon}`} />
        )}
      </div>
      {isOpen ? (
        <div className={classes.yearExpansionPanel}>
          {yearsArray.map((year) => {
            const buttonClass = `${classes.buttonSelectYear} ${
              year === selectedYear ? `${classes.active}` : ''
            }`;
            return (
              <button
                key={year}
                onClick={() => setSelectedYear(year)}
                className={buttonClass}
              >
                {year}
              </button>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default YearPicker;
